.container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10vh;
}

.content {
  max-width: 500px;
  padding: 20px;
  width: 100%;
}

